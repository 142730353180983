<template>
  <PageContainer
    :title="$translations.profile['availability-form-title']"
    :back="actions.close"
    :submit="actions.submit"
    :has-shy-header="true"
  >
    <template v-slot:content>
      <!-- Modality -->
      <ModalitySelector v-model="modality" />
      <template v-if="loading">
        <div class="page-segment">
          <div class="page-segment-content">
            <Skeleton
              class="margin-bottom"
              width="40%"
              height="20px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom"
              width="100%"
              height="48px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom"
              width="100%"
              height="60px"
              borderRadius="16px"
            />
          </div>
        </div>
      </template>

      <template v-else>
        <div
          class="segment"
          v-if="modality === 'FACE_TO_FACE' && !inPersonIsActive"
        >
          <div class="segment-content">
            <InfoBanner
              v-bind="infoBannerData"
              @action="$router.push('/profile/services-list')"
            />
          </div>
        </div>
        <div class="page-segment" v-if="calendarValidation">
          <div class="page-segment-content">
            <TimeInAdvanceForm
              :timeInAdvance="timeInAdvance"
              @timeInAdvanceChanged="setTimeInAdvance"
            />
          </div>
        </div>

        <template v-if="loadingAvailability">
          <div class="page-segment">
            <div class="page-segment-content">
              <Skeleton
                class="margin-bottom-xs"
                width="30%"
                height="25px"
                borderRadius="16px"
              />
              <Skeleton
                class="margin-bottom-xs"
                width="80%"
                height="40px"
                borderRadius="16px"
              />
              <Skeleton
                class="margin-top margin-bottom-xs"
                width="95%"
                height="48px"
                borderRadius="16px"
                v-for="(v, i) of 7"
                :key="i"
              />
            </div>
          </div>
        </template>
        <template v-if="calendarValidation">
          <div class="page-segment">
            <div class="page-segment-content">
              <div
                class="heading-small margin-bottom"
                v-text="$translations.profile.schedule"
              />
              <div class="form-title">
                <div class="content-title" v-text="$translations.form.day" />
                <div class="content-title" v-text="$translations.form.from" />
                <div class="content-title" v-text="$translations.form.to" />
                <div></div>
              </div>
              <div
                class="form-calendar"
                v-for="(model, index) in form"
                :key="index"
              >
                <Checkbox
                  :checked="model.isChecked"
                  :text="$translations.locale.days[model.label]"
                  type="fullcheck"
                  @value="setCheckbox($event, model)"
                />

                <div class="form-calendar-hours-container">
                  <div
                    class="form-calendar-hours-item"
                    v-for="(calendar, index) in model.hours"
                    :key="index"
                  >
                    <Calendar
                      :id="model.label + 'Start'"
                      v-model="calendar.start"
                      :timeOnly="true"
                    />

                    <Calendar
                      :id="model.label + 'End'"
                      v-model="calendar.end"
                      :timeOnly="true"
                    />
                    <div
                      class="form-calendar-icon icon-plus"
                      v-if="index === 0"
                      @click="createHour(model)"
                    ></div>
                    <div
                      class="form-calendar-icon icon-minus"
                      v-if="index > 0"
                      @click="deleteHour(model, index)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="segment body">
            <div class="segment-content body">
              {{ $translations.profile['time-banner'] }}
            </div>
          </div>
        </template>
      </template>
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  Skeleton,
  Checkbox,
  InfoBanner
} from '@seliaco/red-panda'

import Calendar from 'primevue/calendar'
import TimeInAdvanceForm from '@/views/profile/components/schedule/TimeInAdvanceForm'
import ModalitySelector from '@/components/fields/ModalitySelector'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AvailabilityForm',
  components: {
    PageContainer,
    Calendar,
    TimeInAdvanceForm,
    Skeleton,
    Checkbox,
    ModalitySelector,
    InfoBanner
  },
  data () {
    return {
      loading: true,
      form: [
        {
          isChecked: false,
          code: 0,
          label: 'sunday',
          hours: []
        },
        {
          isChecked: false,
          code: 1,
          label: 'monday',
          hours: []
        },
        {
          isChecked: false,
          code: 2,
          label: 'tuesday',
          hours: []
        },
        {
          isChecked: false,
          code: 3,
          label: 'wednesday',
          hours: []
        },
        {
          isChecked: false,
          code: 4,
          label: 'thursday',
          hours: []
        },
        {
          isChecked: false,
          code: 5,
          label: 'friday',
          hours: []
        },
        {
          isChecked: false,
          code: 6,
          label: 'saturday',
          hours: []
        }
      ],
      timeInAdvance: null,
      actions: {
        close: {
          callback: () => this.goBack()
        },
        submit: {
          text: this.$translations.general.save,
          callback: () => this.update(),
          loading: false
        }
      },
      modality: 'ONLINE',
      infoBannerData: {
        icon: 'icon-information-off',
        iconColor: 'yellow',
        background: 'gray-10',
        text: this.$translations.profile.service['face-to-face-disactive'],
        textAction: this.$translations.profile.service.edit
      },
      inPersonIsActive: true
    }
  },
  created () {
    this.$store.dispatch('availability/fetch')
    this.$store.watch(
      () => this.availabilityStore,
      () => {
        this.setForm()
      }
    )
    this.modality = this.modalityStore === 'VIRTUAL' ? 'ONLINE' : 'FACE_TO_FACE'
  },
  methods: {
    goBack () {
      if (this.$route.query.back) {
        this.$router.push(this.$route.query.back)
      } else {
        this.$router.push({ name: 'Profile', query: { back: this.$route.fullPath } })
      }
    },
    setForm () {
      const { timeInAdvance, weekAvailability } = this.availabilityStore

      this.timeInAdvance = timeInAdvance
      if (this.modalityStore === 'IN_PERSON' && Boolean(weekAvailability)) {
        this.inPersonIsActive = Object.keys(weekAvailability)?.length > 0
      } else {
        this.inPersonIsActive = false
      }
      if (weekAvailability) {
        Object.keys(weekAvailability).forEach((key, value) => {
          let hours = {
            start: '00:00',
            end: '00:00'
          }

          if (weekAvailability[key]) {
            const hourGroup = weekAvailability[key].map((i) => {
              if (i.start === '00:00' && i.end === '00:00') {
                return {
                  start: this.$moment(i.start, 'hh:mm').toDate(),
                  end: this.$moment(i.end, 'hh:mm').toDate()
                }
              }

              return {
                start: this.$moment(i.start, 'hh:mm')
                  .tz(this.userTimezone)
                  .toDate(),
                end: this.$moment(i.end, 'hh:mm').tz(this.userTimezone).toDate()
              }
            })

            if (weekAvailability[key].length === 1) {
              hours = weekAvailability[key][0]
            }

            this.form[key].isChecked = !(
              weekAvailability[key].length === 1 &&
              hours.start === '00:00' &&
              hours.end === '00:00'
            )

            this.form[key].hours = [...hourGroup]
          }
        })
      }

      this.loading = false
      this.actions.submit.loading = false
    },
    createHour (model) {
      const array = [...model.hours]
      const lengthArray = array.length
      const lastItem = array[lengthArray - 1]

      model.hours = [
        ...array,
        {
          start: this.$moment(lastItem.end, 'hh:mm').toDate(),
          end: this.$moment(lastItem.end, 'hh:mm').toDate()
        }
      ]
    },
    deleteHour (model, index) {
      model.hours = model.hours.filter((item, inx) => inx !== index)
    },
    setTimeInAdvance (timeInAdvance) {
      this.timeInAdvance = timeInAdvance
    },
    setCheckbox ($event, model) {
      model.isChecked = $event.isChecked

      if (!$event.isChecked) {
        model.hours = [
          {
            start: this.$moment('00:00', 'HH:mm').toDate(),
            end: this.$moment('00:00', 'HH:mm').toDate()
          }
        ]
      }
    },
    update () {
      this.actions.submit.loading = true
      const data = {
        0: {},
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
        6: {}
      }

      this.form.forEach((o) => {
        if (!o.isChecked) {
          data[o.code] = [
            {
              start: '00:00',
              end: '00:00'
            }
          ]
        } else {
          data[o.code] = o.hours.map((i) => {
            return {
              start: this.$moment(i.start)
                .tz(this.userTimezone, true)
                .format('HH:mm')
                .toString(),
              end: this.$moment(i.end)
                .tz(this.userTimezone, true)
                .format('HH:mm')
                .toString()
            }
          })
        }
      })

      let body

      if (this.modality === 'ONLINE') {
        body = {
          timeInAdvance: this.timeInAdvance,
          weekAvailability: data
        }
      } else {
        body = {
          timeInAdvanceInPerson: this.timeInAdvance,
          inPersonWeekAvailability: data
        }
      }

      this.$store
        .dispatch('availability/update', {
          body
        })
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success.availability
          })
          this.$router.go(-1)
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error.default
          })
        })
        .finally(() => {
          this.actions.submit.loading = false
        })
    },
    handlerModality (val) {
      this.loading = true
      this.setModality({ modality: val })
      this.modality = val
    },
    ...mapActions({
      setModality: 'availability/setModality'
    })
  },
  computed: {
    calendarValidation () {
      if (this.availabilityStore?.weekAvailability) {
        return Object.keys(this.availabilityStore.weekAvailability).length > 0
      }

      return false
    },
    ...mapGetters({
      availabilityStore: 'availability/get',
      userTimezone: 'userTimezone',
      modalityStore: 'availability/modality',
      loadingAvailability: 'availability/loading',
      availabilityDefault: 'availability/availabilityDefault'
    })
  },
  watch: {
    modality: {
      handler: 'handlerModality'
    }
  }
}
</script>

<style lang="sass" scoped>
.form-title
  display: grid
  grid-template-columns: 110px 1fr 1fr 40px
  border-bottom: 1px solid var(--gray-10)
  padding-bottom: var(--layout-m)
  margin-bottom: var(--layout-m)
.form-calendar
  display: grid
  grid-template-columns: 100px 3fr
  grid-gap: 8px
  margin-top: var(--layout-m)
  align-items: baseline
  &-hours-container
    display: flex
    flex-direction: column
    gap: 8px
  &-hours-item
    display: grid
    grid-template-columns: 1fr 1fr 40px
    grid-gap: 8px
  &-icon
    display: flex
    align-items: center
    justify-content: center
    font-size: var(--xl-3)
    color: var(--gray-80)
    cursor: pointer

::v-deep .info-banner
  margin: auto
</style>
