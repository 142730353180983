<template>
  <div>
    <div class="heading-small margin-bottom">
      {{ $translations.profile['time-in-advance'] }}
    </div>
    <div class="body margin-bottom">
      {{ $translations.profile['time-in-advance-subtitle'] }}
    </div>
    <Dropdown
      :placeholder="$translations.profile['time-in-advance']"
      :options="optionsMap"
      :value="timeInAdvanceValue"
      :platform="$store.getters.platform"
      @value="selected($event)"
    />
  </div>
</template>

<script>
import { Dropdown } from '@seliaco/red-panda'

export default {
  name: 'TimeInAdvanceForm',
  components: {
    Dropdown
  },
  props: {
    timeInAdvance: Number
  },
  data () {
    return {
      options: [1, 2, 4, 6, 8, 12, 24, 32, 48],
      dateValue: null
    }
  },
  methods: {
    selected (item) {
      this.$emit('timeInAdvanceChanged', item)
    },
    formatValue (item) {
      return `${item} ${
        item < 2
          ? this.$translations.locale.hour
          : this.$translations.locale.hours
      }`
    }
  },
  computed: {
    optionsMap () {
      return this.options.map((item) => ({
        value: item,
        name: this.formatValue(item)
      }))
    },
    timeInAdvanceValue () {
      return this.formatValue(this.timeInAdvance)
    }
  }
}
</script>
